<template>
  <section class="tracking-history">
    <div class="tracking-history-container" v-if="!noOrders">
      <div class="tracking-item" v-for="order in orders" :key="order.id">
        <div class="container">
          <div class="content">
            <div class="tracking-tile">
              <div class="order-info">
                <div class="tracking-no">
                  <div class="tracking-no-text">
                    <div class="label">Tracking No.</div>
                    <div class="number uppercase">
                      {{ order.main_tracking_number }}
                    </div>
                  </div>
                  <div class="latest-status">
                    <div class="icon-wrap active">
                      <img
                        :src="statusIcon(lastStatusValue(order.last_status))"
                        alt="Status icon"
                      />
                    </div>
                    <div class="status-text">
                      <div class="status">
                        {{ lastStatusName(order.last_status) }}
                      </div>
                      <div class="last-update">
                        Last update
                        <span>{{ lastStatusAgo(order.last_status) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="customer-data">
                  <div class="label uppercase">Customer</div>
                  <p class="name">{{ order.customer_details.name }}</p>
                  <p class="email">{{ order.customer_details.email }}</p>
                </div>
                <div class="customer-data">
                  <div class="label uppercase">Drop-off Location</div>
                  <p v-html="order.drop_off_address" class="location"></p>
                </div>
              </div>
              <SlideUpDown :active="active === order.id" :duration="500">
                <div class="toggle-content">
                  <h2 class="details-label">Delivery details</h2>
                  <ul class="order-history">
                    <li
                      class="order-status"
                      v-for="(status, index) in order.status_history"
                      :key="status.id"
                    >
                      <div class="status-type">
                        <div class="icon-wrap">
                          <img
                            :src="
                              index !== order.status_history.length - 1
                                ? icons.successful_delivery.default
                                : statusIcon(status.internal_status.value)
                            "
                            alt="Status icon"
                          />
                        </div>
                        <div class="status-type-text">
                          <div class="status-label">
                            {{ status.internal_status.label }}
                          </div>
                          <div
                            class="substatus uppercase"
                            v-if="status.external_status"
                          >
                            {{ subStatusFormating(status.external_status) }}
                          </div>
                          <time>{{ status.occurred_at | moment }}</time>
                        </div>
                      </div>
                      <div v-html="status.desc" class="status-info"></div>
                    </li>
                  </ul>
                </div>
              </SlideUpDown>
              <div
                class="toggle-details"
                @click="toggleHistory(order.id)"
                :class="{ opened: active === order.id }"
              >
                <span>{{
                  active === order.id ? "Hide details" : "Show details"
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tracking-history-container no-orders" v-if="noOrders">
      <div class="container">
        <div class="content">
          <div class="no-order">
            <img :src="icons.failed.default" alt="Status icon" />
            <div>Given tracking number does not exist</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import SlideUpDown from "vue-slide-up-down";
const axios = require("axios").default;

export default {
  components: {
    SlideUpDown,
  },
  data() {
    return {
      icons: {
        arrive: require("../assets/icon_track.svg"),
        attempt_failed: require("../assets/icon_attempt_failed.svg"),
        created: require("../assets/icon_created.svg"),
        draft: require("../assets/icon_draft.svg"),
        failed: require("../assets/icon_failed.svg"),
        import: require("../assets/icon_import.svg"),
        location: require("../assets/icon_location.svg"),
        out_for_delivery: require("../assets/icon_out_for_delivery.svg"),
        pack: require("../assets/icon_pack.svg"),
        preparing_to_dispatch: require("../assets/icon_preparing_to_dispatch.svg"),
        processing_failed: require("../assets/icon_processing_failed.svg"),
        processing: require("../assets/icon_processing.svg"),
        return_to_sender: require("../assets/icon_return_to_sender.svg"),
        successful_delivery: require("../assets/icon_finished.svg"),
      },
      active: null,
      orders: [],
      noOrders: false,
    };
  },
  mounted() {
    this.$root.$on("tracking-numbers", (resp) => {
      if (resp.length) {
        this.orders = resp;
        this.noOrders = false;
      } else {
        this.noOrders = true;
      }
    });
  },
  filters: {
    moment: function (date) {
      return moment(date).format("h:mm - MM.DD.YYYY");
    },
  },
  methods: {
    statusIcon(status) {
      if (this.icons[status]) {
        return this.icons[status].default;
      }
    },
    toggleHistory(index) {
      if (this.active === null) {
        this.active = index;
      } else if (this.active === index) {
        this.active = null;
      } else {
        this.active = index;
      }
    },
    subStatusFormating(subStatus) {
      return subStatus.replace(/[_-]/g, " ");
    },
    lastStatusValue(lastStatus) {
      return lastStatus.internal_status.value;
    },
    lastStatusName(lastStatus) {
      return lastStatus.internal_status.label;
    },
    lastStatusAgo(lastStatus) {
      const now = moment(new Date());
      const lastStatusDate = moment(lastStatus.occurred_at);
      let duration = moment.duration(now.diff(lastStatusDate));

      if (duration.asMinutes() < 60) {
        if (duration.asMinutes() <= 1) {
          return `1 minute ago`;
        } else {
          return `${parseInt(duration.asMinutes())} minutes ago`;
        }
      } else if (duration.asHours() < 24) {
        if (duration.asHours() <= 1) {
          return `1 hour ago`;
        } else {
          return `${parseInt(duration.asHours())} hours ago`;
        }
      } else if (duration.asDays() < 30) {
        if (duration.asDays() <= 1) {
          return `1 day ago`;
        } else {
          return `${parseInt(duration.asDays())} days ago`;
        }
      } else {
        if (duration.months() <= 1) {
          return `1 month ago`;
        } else {
          return `${parseInt(duration.months())} months ago`;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.tracking-history {
  padding: 55px 0 47px;
  .tracking-item {
    padding-bottom: 40px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .content {
    max-width: 874px;
    margin: auto;
  }
  .tracking-tile {
    width: 100%;
    padding: 0 28px 0 32px;
    background-color: #ffffff;
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.07);
  }
  .order-info {
    display: flex;
    padding-bottom: 4px;
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      width: calc(100% + 60px);
      margin-left: -32px;
      height: 1px;
      background-color: rgba(#979797, 0.14);
    }
    > div {
      width: calc(100% / 3);
      padding: 21px 33px 38px;
      border-right: 1px solid rgba(#979797, 0.14);
      &:first-child {
        padding-left: 0;
      }
      &:nth-child(2) {
        padding-top: 45px;
      }
      &:last-child {
        padding-top: 45px;
        border: none;
      }
    }
  }
  p {
    color: var(--dark);
    font-size: 14px;
    line-height: (18/14);
  }
  .number {
    color: var(--dark);
    font-size: 15px;
    font-weight: 600;
    line-height: (19/15);
  }
  .latest-status {
    margin-top: 26px;
    display: flex;
    .icon-wrap {
      height: 32px;
    }
    img {
      filter: brightness(0) invert(1);
    }
  }
  .status-text {
    margin-left: 8px;
  }
  .status {
    font-size: 12px;
    font-weight: 700;
    line-height: (15/12);
    margin-bottom: 5px;
  }
  .last-update {
    color: rgba(51, 51, 51, 0.5);
    font-size: 10px;
    line-height: (13/10);
  }
  .label {
    color: rgba(51, 51, 51, 0.5);
    font-size: 10px;
    letter-spacing: 0;
    line-height: (13/10);
    margin-bottom: 6px;
  }
  .details-label {
    font-size: 18px;
    letter-spacing: 0;
    line-height: (23/18);
    color: var(--primary);
    padding-top: 30px;
  }
  .icon-wrap {
    width: 32px;
    height: 44px;
    border-radius: 50%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-shrink: 0;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 32px;
      height: 32px;
      border: 1px solid var(--blue);
      z-index: -1;
      border-radius: 50%;
    }
    &.active {
      &::before {
        background-color: var(--blue);
      }
    }
    img {
      position: relative;
      width: 28px;
      height: auto;
    }
  }
  .order-status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 17px 0 25px;
    position: relative;
    &:last-child {
      &::before {
        display: none;
      }
    }
    &::before {
      position: absolute;
      content: "";
      top: 20px;
      left: 16px;
      width: 1px;
      height: 100%;
      background-color: var(--blue);
    }
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      background-color: rgba(#979797, 0.14);
      width: calc(100% - 52px);
      height: 1px;
    }
  }
  .status-type {
    display: flex;
    flex-shrink: 0;
    margin-right: 30px;
  }
  .status-type-text {
    margin-left: 16px;
  }
  .status-label {
    padding-top: 5px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    line-height: (24/16);
    margin-bottom: 4px;
  }
  .substatus {
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    margin-bottom: 4px;
  }
  .status-info {
    font-size: 14px;
    line-height: (20/14);
    color: rgba(0, 0, 0, 0.65);
    text-align: right;
  }
  time {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: (20/14);
  }
  strong {
    font-weight: 700;
  }
  .toggle-details {
    color: var(--dark);
    font-size: 12px;
    font-weight: 600;
    line-height: (15/12);
    text-align: center;
    padding: 20px 0 24px;
    cursor: pointer;
    &.opened {
      span {
        &::before {
          transform: rotate(-135deg);
          top: 3px;
        }
      }
    }
    span {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        top: -3px;
        bottom: 0;
        right: -11px;
        margin: auto 0;
        width: 6px;
        height: 6px;
        border-bottom: 2px solid var(--dark);
        border-right: 2px solid var(--dark);
        transform: rotate(45deg);
      }
    }
  }
  .no-order {
    color: var(--dark);
    font-size: 15px;
    font-weight: 600;
    line-height: 1.26667;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 34px;
      filter: invert(21%) sepia(96%) saturate(7500%) hue-rotate(359deg)
        brightness(115%) contrast(112%);
    }
  }
  @media screen and (max-width: 767px) {
    .order-info {
      flex-direction: column;
      > div {
        width: 100%;
        border-right: none;
        padding: 15px 0px 15px;
        &:first-child {
          display: flex;
          align-items: center;
        }
        &:nth-child(2) {
          padding-top: 15px;
        }
        &:last-child {
          padding-top: 15px;
        }
      }
      .tracking-no-text {
        order: 1;
      }
      .latest-status {
        margin-right: 20px;
        margin-top: 0;
      }
      .status-info {
        max-width: 360px;
      }
    }
  }
  @media screen and (max-width: 460px) {
    .order-status {
      flex-direction: column;
      padding: 9px 0 15px;
    }
    .status-type {
      width: 100%;
      margin: 0;
    }
    .status-info {
      width: 100%;
      text-align: left;
      margin-top: 10px;
      padding-left: 49px;
    }
  }
}
</style>
