<template>
  <div>
    <TrackAndTraceSearch />
    <TrackingHistory />
  </div>
</template>

<script>
import TrackAndTraceSearch from "./components/TrackAndTraceSearch.vue";
import TrackingHistory from "./components/TrackingHistory.vue";
export default {
  name: "App",
  components: {
    TrackAndTraceSearch,
    TrackingHistory,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss"></style>
