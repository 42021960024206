<template>
  <section class="track-n-trace-search">
    <div class="container">
      <div class="content">
        <div class="text-wrap">
          <h1>Track and Trace your package</h1>
          <div class="info">Enter the tracking number</div>
        </div>
        <div class="form-wrap">
          <form action="" @submit.prevent="trackingSubmission" method="post">
            <div class="input-wrap">
              <input
                id="tracking-number"
                type="text"
                name="tracking_number"
                placeholder="Enter Tracking No."
                @keydown="trackingNumbersKeydown($event)"
                v-model="form.trackingNumbers"
              />
            </div>
            <button
              type="submit"
              class="submit-btn"
              :disabled="!form.trackingNumbers.length"
            >
              <span v-if="!loading">Track Package</span>
              <div v-if="loading" class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </button>
          </form>
          <div class="additional-info">
            * Track multiple orders separated by space
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require("axios").default;
const URL_QUERY_PARAM_NAME = 'TN';
const API_URL = process.env.API_URL;

export default {
  data() {
    return {
      loading: false,
      locations: [],
      error: null,
      formInvalid: false,
      form: {
        trackingNumbers: "",
      },
      urlParams: undefined
    };
  },
  created() {
    this.urlParams = new URLSearchParams(window.location.search);
    if (this.urlParams.has(URL_QUERY_PARAM_NAME)) {
      this.form.trackingNumbers = this.urlParams.get(URL_QUERY_PARAM_NAME);
      this.trackingSubmission();
    }
  },
  methods: {
    async fetchLocation(trackingNumbers) {
      this.error = this.locations = null;
      this.loading = true;
      try {
        const response = await axios
          .get(
            `${ API_URL }/front/api/v1/orders/track-trace/?tracking_numbers=${ trackingNumbers }`
          )
          .then((resp) => resp.data)
          .then((resp) => {
            this.loading = false;
            return (this.locations = resp.data);
          });
        this.$root.$emit("tracking-numbers", this.locations);
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    trackingNumbersKeydown(e) {
      if (/[^a-zA-Z0-9 ]/g.test(e.key)) {
        e.preventDefault();
      }
    },
    trackingSubmission() {
      const formData = this.form;
      let trackingNumbers = formData.trackingNumbers;
      trackingNumbers = trackingNumbers.replace(/[% ]/g, ",");
      if (formData.trackingNumbers === "") {
        this.formInvalid = true;
        return;
      }
      this.fetchLocation(trackingNumbers);
      this.updateUrl(trackingNumbers);
      this.formInvalid = false;
    },
    updateUrl(trackingNumbers) {
      this.urlParams.set(URL_QUERY_PARAM_NAME, trackingNumbers);
      window.history.replaceState({}, '', `${window.location.pathname}?${this.urlParams}`);
    }
  },
};
</script>

<style lang="scss">
.track-n-trace-search {
  background-color: var(--bcg-grey);
  padding: 130px 0 104px;
  .content {
    max-width: 877px;
    margin: auto;
  }
  h1 {
    color: var(--primary);
    font-size: 36px;
    line-height: (45/36);
    text-align: center;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .info {
    text-align: center;
    font-size: 15px;
    line-height: (19/15);
  }
  .form-wrap {
    margin: auto;
    max-width: 544px;
    display: flex;
    flex-direction: column;
  }
  form {
    margin-top: 38px;
    display: flex;
    align-items: flex-start;
  }
  .input-wrap {
    max-width: 370px;
    width: 100%;
    margin-right: 24px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 1;
    }
    &:nth-child(1) {
      &::after {
        background-image: url("../assets/icon_pack.svg");
        width: 16px;
        height: 16px;
        left: 13px;
      }
      input {
        &:focus {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid var(--blue);
        }
      }
    }
    &:nth-child(2) {
      max-width: 300px;
      &::after {
        background-image: url("../assets/icon_location.svg");
        width: 13px;
        height: 18px;
        left: 17px;
      }
      input {
        height: 48px;
      }
    }
  }
  input {
    padding: 0 37px;
    display: block;
    height: 50px;
    width: 100%;
    border-radius: 2px;
    background-color: var(--white);
    font-size: 14px;
    letter-spacing: 0.054em;
    line-height: (18/14);
    transition: 0.3s ease-in-out;
    outline: none;
    border: 1px solid transparent;
    margin: 0 !important;
    color: var(--dark-grey);
    &::placeholder {
      opacity: 1;
      color: var(--grey);
    }
  }
  .vs__search {
    &:focus {
      padding: 0 37px;
    }
  }
  .v-select {
    transition: 0.3s ease-in-out;
    border: 1px solid transparent;
    border-radius: 2px;
    background-color: var(--white);
    &::after {
      position: absolute;
      content: "";
      transition: transform 0.3s ease-in-out;
      transform: rotate(0);
      height: 7px;
      width: 14px;
      background-image: url("../assets/select_arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      right: 24px;
      bottom: 0;
      margin: auto 0;
      z-index: 1;
    }
    &.vs--open {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid var(--blue);
      &::after {
        transform: rotate(-180deg);
      }
    }
  }
  .vs__dropdown-toggle {
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    padding: 0;
  }
  .vs__dropdown-menu {
    top: calc(100% + 1px);
  }
  .vs__selected-options {
    padding: 0;
    position: relative;
  }
  .vs__dropdown-option {
    font-size: 14px;
    letter-spacing: 0.054em;
    line-height: (18/14);
    color: var(--dark-grey);
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  .vs__selected {
    font-size: 14px;
    letter-spacing: 0.054em;
    line-height: (18/14);
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 0 37px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 280px;
    width: 100%;
    display: inline-block;
    line-height: 47px;
  }
  .vs__actions {
    padding-right: 25px;
    padding-top: 0;
    svg {
      max-width: none;
    }
  }
  .vs__open-indicator {
    opacity: 0;
  }
  .vs__clear {
    pointer-events: none;
    opacity: 0;
  }
  .submit-btn {
    flex-shrink: 0;
    padding: 18px 0 17px;
    text-align: center;
    text-transform: uppercase;
    width: 150px;
    border-radius: 2px;
    background-color: transparent;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.05em;
    line-height: (15/12);
    border: none;
    color: var(--white);
    position: relative;
    z-index: 1;
    &::before {
      content: "";
      display: block;
      height: calc(100% + 2px);
      left: 0;
      position: absolute;
      top: 0;
      width: calc(100% + 2px);
      z-index: -2;
      background-color: var(--blue);
      border-radius: 2px;
    }
    &::after {
      background-color: var(--white);
      content: "";
      display: block;
      height: calc(100% + 2px);
      left: 0;
      position: absolute;
      top: 0;
      width: calc(100% + 2px);
      -webkit-clip-path: ellipse(0 0 at 50% 100%);
      clip-path: ellipse(0 0 at 50% 100%);
      transition: -webkit-clip-path 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        background-color 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: clip-path 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        background-color 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: clip-path 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        background-color 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -webkit-clip-path: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        background-color 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      z-index: -1;
    }
    &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }
    &:not([disabled]) {
      @media not all and (hover: none) {
        &:hover {
          color: var(--blue);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          &::after {
            -webkit-clip-path: ellipse(200% 105% at 50% 100%);
            clip-path: ellipse(200% 105% at 50% 100%);
          }
          .lds-ellipsis {
            div {
              background: var(--blue);
            }
          }
        }
      }
      &:active {
        &::after {
          background-color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }
  .additional-info {
    font-size: 12px;
    line-height: (15/12);
    margin-left: 14px;
    margin-top: 11px;
  }
  .lds-ellipsis {
    display: block;
    height: 12px;
    left: -8px;
    margin: 0 auto;
    position: relative;
    width: 38px;
    div {
      -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
      background: var(--white);
      border-radius: 50%;
      height: 10px;
      position: absolute;
      top: 4px;
      width: 10px;
      &:first-child {
        -webkit-animation: lds-ellipsis1 0.6s infinite;
        animation: lds-ellipsis1 0.6s infinite;
        left: 6px;
      }
      &:nth-child(2) {
        left: 6px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        -webkit-animation: lds-ellipsis2 0.6s infinite;
        animation: lds-ellipsis2 0.6s infinite;
      }
      &:nth-child(3) {
        left: 24px;
      }
      &:nth-child(4) {
        -webkit-animation: lds-ellipsis3 0.6s infinite;
        animation: lds-ellipsis3 0.6s infinite;
        left: 42px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 50px 0 64px;
  }
  @media screen and (max-width: 767px) {
    form {
      flex-direction: column;
      max-width: 500px;
      width: 100%;
      margin-inline: auto;
    }
    .input-wrap {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
      &:nth-child(2) {
        max-width: 100%;
      }
    }
    .submit-btn {
      margin-left: auto;
    }
    .additional-info {
      max-width: 500px;
      width: 100%;
      margin-inline: auto;
    }
  }
  @media screen and (max-width: 630px) {
    max-width: 310px;
    margin: auto;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    to {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0);
    }
    to {
      transform: translate(18px);
    }
  }
}
</style>
