import "../styles/style.scss";
import lottie from "lottie-web";
import Vue from "vue";

// if (process.env.NODE_ENV !== "development") {
//   document.addEventListener("contextmenu", (event) => event.preventDefault());
// }

const SELECTOR = "#app";
const App = require("./vue/App.vue").default;

document.addEventListener("DOMContentLoaded", () => {
  const app = document.querySelector(SELECTOR);
  if (app) {
    new Vue({
      el: SELECTOR,
      render: (h) => h(App),
    });
  }

  const truck = document.querySelector(".lottie-animation.truck");

  if (window.innerWidth >= 1280) {
    const jetpack = document.querySelector(".lottie-animation.jetpack");
    const jetpackAnim = lottie.loadAnimation({
      container: jetpack, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: jetpack.getAttribute("path"), // the path to the animation json
      initialSegment: [0, 90],
    });
    jetpackAnim.playSegments([
      [90, 200],
      [200, 90],
    ]);
  }

  const truckAnim = lottie.loadAnimation({
    container: truck, // the dom element that will contain the animation
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: truck.getAttribute("path"), // the path to the animation json
    initialSegment: [0, 24],
  });
  truckAnim.playSegments([
    [25, 50],
    [45, 25],
  ]);
});
